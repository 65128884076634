






































import { defineComponent, inject, ref, computed, watchEffect } from '@vue/composition-api';
import MongoDoc from './types';

export default defineComponent({
  name: 'ModuleMonitor',

  props: {
    programDoc: {
      required: true,
      type: Object as () => MongoDoc
    },
    userDoc: {
      required: true
    },
    studentDoc: {
      required: true
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const showInstructions = ref(true);
    const redoSignoff = ref(false);
    const isFetching = ref(false);

    const loaded = ref(false);
    const videoAskResponse: any = ref({});
    const isSignoffCompleted = ref(false);

    const studentDocument: any = computed({
      get: () => props.studentDoc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = studentDocument.value?.data?.adks?.findIndex(obj => {
      if (obj) {
        return obj.name === 'signoff';
      }
      return false;
    });

    function fetchStatus() {
      try {
        isFetching.value = true;
        const setFunction = setInterval(() => {
          props.studentDoc.update();
          if (isSignoffCompleted.value) {
            clearInterval(setFunction);
          }
        }, 10000);
        isFetching.value = false;
      } catch (error) {
        isFetching.value = false;
        console.log(error);
      }
    }

    watchEffect(() => {
      if (
        index !== -1 &&
        studentDocument.value?.data &&
        studentDocument.value?.data?.adks[index]?.completed
      ) {
        isSignoffCompleted.value = true;
      }
      if (
        index !== -1 &&
        studentDocument.value?.data &&
        studentDocument.value?.data?.adks[index]?.signOffVideoaskResponse
      ) {
        videoAskResponse.value = studentDocument.value?.data?.adks[index]?.signOffVideoaskResponse;
      }
    });

    return {
      readonly,
      showInstructions,
      fetchStatus,
      isSignoffCompleted,
      redoSignoff,
      isFetching,
      loaded,
      studentDocument,
      videoAskResponse
    };
  }
});
